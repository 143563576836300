import React, { useEffect } from "react";
import SEO from "root/components/SEO";
import Layout from "root/components/Layout";
import Navbar from "root/components/Navbar";
import Hero from "root/sections/AboutHero";
import AboutResult from "root/sections/AboutResult";
import AboutResultVideo from "root/sections/AboutResultVideo";
import AboutWaitMore from "root/sections/AboutWaitMore";
import AboutSmiles from "root/sections/AboutSmiles";
import Footer from "root/components/Footer";
import Apoios from "root/components/Apoios";
import { useTranslation } from "react-i18next";

const AboutPage = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage("pt-PT");
  }, []);

  return (
    <div>
      <SEO title={t("pages.about.label")} keywords="" />
      <Layout>
        <Navbar color="blue" currentPage="about" />
        <Hero translations={t("about.hero")} />
        <AboutResult />
        <AboutResultVideo />
        <AboutWaitMore />
        <AboutSmiles />
        <Footer />
        <Apoios />
      </Layout>
    </div>
  );
};

export default AboutPage;
